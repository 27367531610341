import React from "react"
import Webform from "gatsby-drupal-webform"

import WebformFieldCheckbox from "./Webform/WebformFieldCheckbox"
import WebformFieldCheckboxes from "./Webform/WebformFieldCheckboxes"
import WebformFieldEmail from "./Webform/WebformFieldEmail"
import WebformFieldText from "./Webform/WebformFieldText"
import WebformFieldTextarea from "./Webform/WebformFieldTextarea"
import WebformFieldPhone from "./Webform/WebformFieldPhone"
import WebformFieldSelect from "./Webform/WebformFieldSelect"
import WebformFieldRadios from "./Webform/WebformFieldRadios"

const Form = ({ webform, ...props }) => {
  const webformId = "webform-" + Math.floor(Math.random() * Date.now())

  const onSuccess = response => {
    if (response.settings) {
      window.flash(response.settings.confirmation_message, "success")

      document.getElementById(webformId).reset()
    }
  }

  const onValidate = event => {
    event.preventDefault()

    let isValid = event.currentTarget.checkValidity()

    if (!isValid) {
      window.flash(
        "Au moins un champ de ce formulaire n'est pas conforme. Merci de contrôler votre saisie.",
        "danger"
      )
    }

    return isValid
  }

  return (
    <>
      <div className="mb-4 font-important font-semibold" aria-hidden="true">
        Les champs marqu&eacute;s d&apos;un ast&eacute;risque (*) sont
        obligatoires
      </div>

      <Webform
        id={webformId}
        webform={webform}
        customComponents={{
          textfield: WebformFieldText,
          email: WebformFieldEmail,
          tel: WebformFieldPhone,
          checkbox: WebformFieldCheckbox,
          checkboxes: WebformFieldCheckboxes,
          select: WebformFieldSelect,
          textarea: WebformFieldTextarea,
          radios: WebformFieldRadios,
        }}
        endpoint={`${process.env.API_URL}${process.env.WEBFORM_ENDPOINT}`}
        onSuccess={onSuccess}
        onValidate={onValidate}
        {...props}
      />
    </>
  )
}

export default Form
