import React from "react"
import { graphql } from "gatsby"
import { getParagraph, getSidebarParagraph } from "../helpers/paragraph-helpers"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import PageHeader from "../components/PageHeader"
import Form from "../components/Form"
import MenuLink from "../components/MenuLink"
import HtmlParser from "../components/HtmlParser"
import NeedHelpBlock from "../components/Blocks/NeedHelpBlock"
import Block from "../components/Block"

const Page = ({ pageContext, data, location }) => {
  const exludedIds = [71]
  const isExcluded = exludedIds.includes(pageContext.internalId)
  const typeSense = isExcluded ? {} : { "data-typesense-field": "body" }
  const paragraphs = data.node.relationships.paragraphs.map(node => {
    return getParagraph(node, isExcluded)
  })
  const sidebarParagraphs =
    data.node.relationships.sidebar.map(getSidebarParagraph)
  const hasSidebar =
    (data.children && data.children.nodes.length > 0) ||
    sidebarParagraphs.length > 0

  return (
    <Layout contentType={isExcluded ? null : data.node.internal.type}>
      <Metas title={data.node.title} location={location}/>
      <PageHeader title={data.node.title} isExcluded={isExcluded} />
      <section className="section page-content">
        <div className="container mx-auto px-4">
          <div
            className={`grid grid-cols-1 ${
              hasSidebar ? `lg:grid-cols-4` : ``
            } gap-8`}
          >
            <div className={hasSidebar ? `lg:col-span-3` : ``}>
              {data.node.body && (
                <div className="block content mb-8 lg:px-24" {...typeSense}>
                  <HtmlParser html={data.node.body.value} />
                </div>
              )}

              <div className="paragraphs-list">{paragraphs}</div>

              {data.node.relationships.webform && (
                <section className="page-webform lg:px-24">
                  <Form webform={data.node.relationships.webform} />
                </section>
              )}

              <Block id="need-help--block" className="section">
                <div className="border border-primary shadow-primary p-8 lg:px-24 lg:py-12">
                  <NeedHelpBlock titleSize={2} />
                </div>
              </Block>
            </div>
            {hasSidebar && (
              <aside>
                {data.children && data.children.nodes.length > 0 && (
                  <div className="childrens grid grid-cols-1">
                    <div className="title h4 mb-4">
                      <span>{data.node.title}</span>
                    </div>
                    {data.children.nodes.map((item, index) => (
                      <div key={index} className="child-link">
                        <MenuLink
                          internalId={item.link.uri}
                          className="block py-6 px-2 border-b border-gray-300 text-blue-night font-semibold hover:text-primary-light"
                        >
                          {item.title}
                        </MenuLink>
                      </div>
                    ))}
                  </div>
                )}
                {sidebarParagraphs.length > 0 && (
                  <div className="sidebar-paragraphs-list">
                    {sidebarParagraphs}
                  </div>
                )}
              </aside>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ($internalId: Int!, $menuItemId: String) {
    node: nodePage(
      status: { eq: true }
      drupal_internal__nid: { eq: $internalId }
    ) {
      title
      body {
        summary
        value
      }
      changed
      relationships {
        paragraphs: field_paragraphes {
          type: __typename
          ... on Node {
            ...TextParagraph
            ...BannerParagraph
            ...HighlightedParagraph
            ...ListParagraph
            ...FilesMultiuploadParagraph
            ...AccordionParagraph
            ...CollapseParagraph
            ...ImageParagraph
            ...ImageGalleryParagraph
          }
        }
        sidebar: field_sidebar {
          type: __typename
          ... on Node {
            ...LinksParagraph
            ...FilesMultiuploadParagraph
          }
        }
        webform: field_webform {
          title
          drupal_internal__id
          elements {
            name
            type
            attributes {
              name
              value
            }
            options {
              label
              value
            }
          }
        }
      }
      internal {
        type
      }
    }
    children: allMenuLinkContentMenuLinkContent(
      filter: {
        enabled: { eq: true }
        menu_name: { eq: "main" }
        drupal_parent_menu_item: { eq: $menuItemId, ne: null }
      }
      sort: { fields: weight, order: ASC }
    ) {
      nodes {
        title
        drupal_parent_menu_item
        drupal_id
        link {
          uri
          options {
            attributes {
              data_has_icon
              data_icon
              data_text_invisible
              target
            }
          }
        }
      }
    }
  }
`
