import React from "react"
import { useWebformElement, WebformElementWrapper } from "gatsby-drupal-webform"

const WebformFieldText = ({ element, error }) => {
  const [inputProps, settings] = useWebformElement(element, {
    name: element.name,
    type: "text",
  })

  return (
    <WebformElementWrapper
      className="field"
      settings={settings}
      error={error}
      labelFor={element.name}
      labelClassName={`label${inputProps.required ? ` required` : ``}`}
    >
      <div className="control">
        <input id={element.name} className="input" {...inputProps} />
      </div>
    </WebformElementWrapper>
  )
}

export default WebformFieldText
