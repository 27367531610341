import React from "react"
import { useWebformElement, WebformElementWrapper } from "gatsby-drupal-webform"

const WebformFieldTextarea = ({ element, error }) => {
  const [inputProps, settings] = useWebformElement(element, {
    name: element.name,
    type: "textarea",
  })

  return (
    <WebformElementWrapper
      className="field md:col-span-2"
      settings={settings}
      error={error}
      labelFor={element.name}
      labelClassName={`label${inputProps.required ? ` required` : ``}`}
    >
      <div className="control">
        <textarea
          id={element.name}
          className="textarea"
          {...inputProps}
        ></textarea>
      </div>
    </WebformElementWrapper>
  )
}

export default WebformFieldTextarea
