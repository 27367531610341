import React from "react"
import PropTypes from "prop-types"
import { cssCleanClass } from "../Utils/functions"

const Block = ({ children, section, container, background, ...props }) => {
  const withContainer = childrens => (
    <div className="container mx-auto px-4">{childrens}</div>
  )

  if (props.className === undefined) {
    props.className = ""
  }

  if (section) {
    props.className += " section"
  }

  if (background) {
    props.className += " has-background-" + cssCleanClass(background)
  }

  return <div {...props}>{container ? withContainer(children) : children}</div>
}

Block.propTypes = {
  children: PropTypes.node.isRequired,
  section: PropTypes.bool,
  container: PropTypes.bool,
  background: PropTypes.string,
}

Block.defaultProps = {
  section: false,
  container: false,
  background: null,
}

export default Block
