import React, { useState } from "react"
import { useWebformElement, WebformElementWrapper } from "gatsby-drupal-webform"
import { classNames } from "../../Utils/functions"

const WebformFieldCheckbox = ({ element, error }) => {
  const [checked, setChecked] = useState(false)

  const [inputProps, settings] = useWebformElement(element, {
    name: element.name,
    type: "checkbox",
  })

  return (
    <WebformElementWrapper
      className="field md:col-span-2 flex items-baseline"
      settings={settings}
      error={error}
      labelFor={element.name}
      labelClassName="label-inline flex-1 order-2"
    >
      <div className="control-inline flex-initial order-1">
        <input
          id={element.name}
          className="checkbox sr-only"
          onChange={() => setChecked(!checked)}
          {...inputProps}
        />
        <div
          className={classNames("checkbox-icon", checked && "checked")}
          aria-hidden="true"
        >
          <i
            className={classNames(
              "mr-2 fas",
              checked ? "fa-check-square" : "fa-square"
            )}
          ></i>
        </div>
      </div>
    </WebformElementWrapper>
  )
}

export default WebformFieldCheckbox
