import React, { useState } from "react"
import { useWebformElement, WebformElementWrapper } from "gatsby-drupal-webform"
import { classNames } from "../../Utils/functions"

const WebformFieldCheckboxes = ({ element, error }) => {
  const [checked, setChecked] = useState([])

  const [inputProps, settings] = useWebformElement(element, {
    name: element.name,
    type: "checkbox",
  })

  const changeHandler = event => {
    let value = event.target.value
    let checkedValues = checked

    if (checkedValues.includes(value)) {
      let index = checkedValues.indexOf(value)
      checkedValues.splice(index, 1)
    } else {
      checkedValues = [...checkedValues, value]
    }

    setChecked([...checkedValues])
  }

  return (
    <>
      {element.options && element.options.length > 0 && (
        <WebformElementWrapper
          className="field md:col-span-2"
          settings={settings}
          error={error}
          labelFor={element.name}
          labelClassName={`label${inputProps.required ? ` required` : ``}`}
        >
          <div className="checkboxes flex flex-wrap flex-col md:flex-row -mx-4">
            {element.options.map(({ label, value }, index) => {
              let elementId = `${element.name}_${value}`
              let isChecked = checked.includes(value)

              return (
                <div key={index} className="control px-4">
                  <input
                    id={elementId}
                    name={element.name}
                    value={value}
                    className="checkbox sr-only"
                    onChange={changeHandler}
                    {...inputProps}
                  />

                  <span
                    className={classNames(
                      "checkbox-icon",
                      isChecked && "checked"
                    )}
                    aria-hidden="true"
                  >
                    <i
                      className={
                        isChecked ? `far fa-check-square` : `far fa-square`
                      }
                    ></i>
                  </span>

                  <label htmlFor={elementId} className="label-inline px-2">
                    {label}
                  </label>
                </div>
              )
            })}
          </div>
        </WebformElementWrapper>
      )}
    </>
  )
}

export default WebformFieldCheckboxes
