import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import HtmlParser from "../HtmlParser"
import MenuLink from "../MenuLink"

const NeedHelpBlock = ({ title, titleDisplay, titleSize }) => {
  const data = useStaticQuery(graphql`
    {
      block: blockContentBasic(drupal_internal__id: { eq: 6 }) {
        info
        body {
          value
        }
        links: field_links {
          title
          uri
        }
      }
    }
  `)

  return (
    <>
      {titleDisplay && (
        <>
          <div className="icon text-primary-light text-2xl mb-4">
            <span className="fas fa-question-circle">
              <span className="hidden">{data.block.info}</span>
            </span>
          </div>
          <h2 className={`title h${titleSize}`}>
            <span>{title ? title : data.block.info}</span>
          </h2>
        </>
      )}
      <div className="content">
        <HtmlParser html={data.block.body.value} />
      </div>
      {data.block.links && data.block.links.length > 0 && (
        <div className="links mt-8">
          <div className="flex flex-col md:flex-row flex-wrap md:items-center -mx-2">
            {data.block.links.map((link, index) => {
              let type = link.uri.split(":")[0]
              return (
                <div key={index} className="px-2 mb-2">
                  <MenuLink
                    internalId={link.uri}
                    className="button button-primary inline-flex items-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="icon mr-2 text-primary-light">
                      {type === "tel" ? (
                        <span className="fas fa-phone"></span>
                      ) : type === "mailto" ? (
                        <span className="fas fa-envelope"></span>
                      ) : (
                        <span className="fas fa-external-link-square-alt"></span>
                      )}
                    </span>
                    {link.title}
                  </MenuLink>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

NeedHelpBlock.propTypes = {
  title: PropTypes.string,
  titleDisplay: PropTypes.bool,
  titleSize: PropTypes.number,
}

NeedHelpBlock.defaultProps = {
  title: "",
  titleDisplay: true,
  titleSize: 3,
}

export default NeedHelpBlock
